@import '../../../../../styles/abstracts/index';

.root {
  padding: spacing(6);
  flex-grow: 1;
}

.body {
  display: grid;
  grid-template-areas:
      'row-top row-right-top'
      'row-mid row-right-bottom';
  gap: spacing(5) spacing(4);
  grid-template-columns: 2fr 1fr;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;

  a {
    color: $color-primary !important;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
}

.icon {
  margin-right: spacing(1);
}

.listItem {
  margin-bottom: spacing(0.5);
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.body p {
  //display: -webkit-box;
  //-webkit-line-clamp: 6;
  //-webkit-box-orient: vertical;
  //overflow: hidden;
}

.col {
  &:nth-child(1) {
    grid-area: row-top;
  }
  &:nth-child(2) {
    grid-area: row-mid;
  }
  &:nth-child(3) {
    grid-area: row-right-top;
  }
  &:nth-child(4) {
    grid-area: row-right-bottom;
  }
}

.footer {
  padding: 0;
  padding-top: spacing(4);

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid $color-monochrome-line;
}

.author {
  display: flex;
  align-items: center;
}

.authorDescription {
  margin-left: spacing(3);
}

.actions {
  display: flex;
  align-items: center;

  a:not(:last-child) {
    margin-right: spacing(2);
  }
}

@include respond(tab-port) {
  .body {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    padding-bottom: spacing(4);
  }

  .col {
    width: 100%;

  }

  .footer {
    flex-direction: column;
    align-items: stretch;
  }

  .actions {
    margin-top: spacing(5);
  }

  .actions > * {
    flex: 1 1 50%;
    word-wrap: normal;
    white-space: nowrap;
  }
}
